import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';

export const LinkItem = ({ className, text, href }) => {

  return (
    <div
      className={cn(
        className,
        'mb-9 link-item'
      )}
    >
      <a href={href} className="relative cursor-pointer">
        <span className="text-orange relative">{text}</span>
        </a>
    </div>
  );
};

LinkItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};