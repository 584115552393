import PropTypes from 'prop-types';
import cn from 'classnames';
// import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Questions = ({ className = '' }) => {
  // const { t } = useTranslation();
  return (
    <div className={cn(className, 'block wrapper-lg pt-10 xs:pt-44')}>
      <div className="m-auto">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="flex flex-col xs:flex-row items-center justify-between">
            <div className="flex items-center xs:max-w-[45%] flex-col xs:flex-row">
              <p className="text-[50px] leading-[50px] bg-[#ffeee8] rounded-full py-[20px] px-[20px]  mr-5">👑</p>
              <div className='text-center xs:text-left mt-10 xs:mt-0'>
                <h3 className="uppercase">Looking to STAND OUT?</h3>
                <p className="p3">
                  Take advantage of our VIP membership and receive acess to
                  exclusive perks
                </p>
              </div>
            </div>
            <span className="divider-vert hidden xs:block"></span>
            <div className="flex items-center xs:max-w-[45%] flex-col mt-10 xs:mt-0 xs:flex-row">
              <p className="text-[50px] leading-[50px] bg-[#ffeee8] rounded-full py-[20px] px-[20px]  mr-5">🔥</p>
              <div className='text-center xs:text-left mt-10 xs:mt-0'>
                <h3 className="uppercase">
                  ARE YOU AN AUTHORITY <br /> IN YOUR FIELD?
                </h3>
                <p className="p3">
                  Become an Influencer and share your expertise through an
                  extended reach worldwide
                </p>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

Questions.propTypes = {
  className: PropTypes.string
};
