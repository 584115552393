import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ImagenHero from 'assets/img/landing-hero-user.png';
import { ScrollButton, OurApp } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-scroll';
import './index.css';


export const HeroUser = ({ className = '', id }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'block wrapper-lg py-4 xs:pt-44 xs:pb-20'
      )}
      id={id}
    >
      <div className="w-full m-auto" activeClass="active">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <h1 className="text-3xl xs:text-7xl uppercase text-center font-bold leading-10 block">
            {t('ShareYour')} <br />
            <p>
              <span className="bg-orange text-white leading-tight">{t('PhotosAndVideos')}</span>
              &nbsp;{t('OnThe')}
            </p>
            {t('FirstCraneSocialNetwork')}
          </h1>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={400}>
        <div className="flex items-center justify-center">
          <Link
            className="py-1 z-10 anchor cursor-pointer scroll-button-container absolute top-5 right-1/2 translate-x-2/4"
            to="cv"
            activeClass="active"
            smooth="true"
            duration={500}
          >
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={350}>
              <ScrollButton className="" style={{ Transform: 'translateX(50%)' }} />
            </AnimationOnScroll>
          </Link>
          <div className="mt-8 relative bg-center bg-cover">
            <img src={ImagenHero} alt="" />
            <div>
            </div>
          </div>
          <div className="hidden xs:block">
            <OurApp />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

HeroUser.propTypes = {
  className: PropTypes.string

};