import { Header,  Footer } from 'components';
import PropTypes from 'prop-types';

const Layout = ({ children }) => {
  return (
    <div className="w-full h-screen">
      <main className="">{children}</main>
    </div>
  );
};

Layout.propTypes = {
  className: PropTypes.node
};

export default Layout;
