import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';

export const TitleItem = ({ className, text }) => {
  
  return (
    <div
      className={cn(
        className,
        'mb-4 xs:mb-9 text-center'
      )}
    >
    <h3>{text}</h3>
    </div>
  );
};

TitleItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
};