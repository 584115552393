import React, { useEffect, useState } from 'react';
import { getFeaturedBrands } from 'api/brands';
import { BRANDS } from './brands'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive'
SwiperCore.use([Autoplay]);

export const AutomaticSlideBrands = ({ className, notClick, id }) => {
  const [brands, setBrands] = useState([]);

  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  useEffect (()=> {
    fetchBrands();
  }, []); 

  const fetchBrands = async () => {
    const _brands = await getFeaturedBrands();
    setBrands(_brands);
  }

  
  return (
    <div className="mt-7 md:mt-12 bg-white rounded-lg py-2 md:py-10">
      <Swiper
        spaceBetween={24}
        slidesPerView={ isMobile? 3 : 5}
        freeMode={true}
        loop={true}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        className="thumbs-swiper"
        id={id}
      >
        {brands?.map(({ logo, name, id }, index) => (
          <SwiperSlide className="flex items-center justify-center" key={id}>
            <div
              onClick={() => null }
              className={cn(
                'p-0 rounded-lg hover:bg-white flex justify-center items-center',
                {
                  'cursor-pointer': !notClick
                }
              )}
              key={index}
            >
              <img src={logo} alt={name} className="transform scale-125" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
