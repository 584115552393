import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import { getFeatured } from 'api/users';
import Club from 'assets/img/club.png';
import { Button, UserCard } from '@estudio-nk/the-crane-club-client-lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import './index.css';
import { useTranslation } from 'react-i18next';


SwiperCore.use([Autoplay]);

export const SlideUsers = ({ className, id }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    const users = await getFeatured(0, 20);
    setUsers(users?.rows || []);
  };

  const { t } = useTranslation();

  return (
    <div className={cn(className, 'bg-black-primary relative fade-section-black py-18')} id={id}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div
          className="bg-black-primary py-22 pb-[5%] bg-no-repeat"
          style={{
            backgroundImage: `url(${Club})`,
            backgroundPosition: `center bottom`,
            backgroundSize: `contain`
          }}
        >
          <div className="wrapper-lg h-full ">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={200}>
              <h2 className="text-white uppercase">
                <span className="bg-orange text-white mr-2 xs:mr-5">{t('JoinUs')}</span>
                {t('bePartOf')} <br />
                {t('craneLovingCommunity')}
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
              <p className="text-white text-xs md:text-2xl text-center mt-5">
                {t('withUsersBrandsVIP')}
              </p>
            </AnimationOnScroll>
            <div>
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                <div className="xs:mt-7 md:mt-12 bg-transparent rounded-lg">
                  <Swiper
                    spaceBetween={24}
                    slidesPerView={1}
                    breakpoints={{
                      1200: {
                        slidesPerView: 5
                      },
                      800: {
                        slidesPerView: 3
                      },
                      600: {
                        slidesPerView: 2
                      }
                    }}
                    freeMode={true}
                    loop={true}
                    // autoplay={{
                    //   delay: 2000,
                    //   pauseOnMouseEnter: true,
                    //   disableOnInteraction: false
                    // }}
                    className="thumbs-swiper swiper-container-cards"
                  >
                    {users?.map((element, index) => (
                      <SwiperSlide
                        className="max-w-sm "
                        key={`${index}${element.uid}`}
                      >
                        <UserCard user={element} className="min-h-[260px]" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="text-center wrapper-lg">
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                <div className="w-3/6 md:w-1/6 m-auto">
                  <a href="https://thecraneclub.com/auth/login" target="_blank">
                    <Button className="mb-6">{t('joinTheClub')}</Button>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

SlideUsers.propTypes = {
  className: PropTypes.string
};
