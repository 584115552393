import React from 'react';
import Layout from 'Layout';
import { Hero, Header, SectionVideo ,SlideCranes, SlideUsers, AutomaticSlideBrands, TitleDivision, ContainerButton, SimpleSearch } from 'components';
import { BussinessProfile, UserProfile, Footer, BannerDownload, CraneCommunity, BankJob, AboutTCC, Questions, Metrics } from 'components';


const VIDEOID = process.env.REACT_APP_YOUTUBE_VIDEO_ID

const Landing = () => {
  return (
    <Layout>
      <>
        <Header />
        <Hero id="hero"/>
        <BannerDownload />
        <AboutTCC />
        <SlideCranes id="find-cranes"/>
        <AutomaticSlideBrands id=""/>
        <SlideUsers id="community"/>
        <TitleDivision id=""/>
        <BussinessProfile id=""/>
        <UserProfile id=""/>
        <Questions />
        <SimpleSearch />
        <BankJob id=""/>
        <CraneCommunity className="pt-[5rem] xs:pt-[10rem]"/>
        <Metrics />
        <SectionVideo 
          className="pb-[5rem] pt-5"
          videoId={VIDEOID}
          id="our-reel"
          text="See our reel"
          textClasses="absolute bottom-1 px-12 rounded-bl left-0"
        />
        <Footer id="footer" 
        className="mt-[30px] md:mt-0"
        />
      </>
    </Layout>
  );
};

export default Landing;
