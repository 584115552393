import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ImagenHero from 'assets/img/landing-hero.png';
import { ScrollButton, OurApp } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-scroll';
import './index.css';


export const Hero = ({ className = '' }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'block wrapper-lg pt-10 xs:pt-44'
      )}
    >
      <div className="w-11/12 m-auto" activeClass="active">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <h1 className="text-3xl md:text-8xl uppercase text-center font-bold leading-tight">
            <p>{t('RentBuySellCranes')}
              <span className="bg-orange ml-2 xs:ml-5 text-white leading-tight">{t('now')} 100% {t('free')}</span>
            </p>
          </h1>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
          <div className="text-right mt-2 xs:mt-5">
            <span className="xs:text-2xl text-orange text-right w-fit block span-animation relative mr-[20%] ml-auto">{t('zeroCommission')}</span>
          </div>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={400}>
        <div className="flex items-center justify-center">
          <Link
            className="py-1 z-10 anchor cursor-pointer scroll-button-container absolute top-5 right-1/2 translate-x-2/4"
            to="cv"
            activeClass="active"
            smooth="true"
            duration={500}
          >
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={350}>
              <ScrollButton className="" style={{ Transform: 'translateX(50%)' }} />
            </AnimationOnScroll>
          </Link>
          <div className="mt-8 relative bg-center bg-cover">
            <img src={ImagenHero} alt="" />
            <div>
            </div>
          </div>
          <div className="hidden xs:block">
            <OurApp />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};