import PropTypes from 'prop-types';
import cn from 'classnames';
import { YoutubeEmbed, LabelVideo } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';

export const SectionVideo = ({ className = '', videoId, id, textClasses, text }) => {
  return (
    <div
      className={cn(
        className,
        'block wrapper-md relative'
      )}
      id={id}
    >
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <YoutubeEmbed embedId={videoId} />
        <LabelVideo className={textClasses} text={text} />
      </AnimationOnScroll>
    </div>
  );
};

SectionVideo.propTypes = {
  className: PropTypes.string
};