import axios from 'utils/axios';

export const getFeatured = async (page = 0, size = 15, orderBy = 'id-DESC') => {
  try {
    const url = `api/publications/featured?page=${page}&size=${size}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
