import PropTypes from 'prop-types';
import cn from 'classnames';
import SwiperCore, { Autoplay } from 'swiper/core';
import imgItem1 from 'assets/img/about/1.png';
import imgItem2 from 'assets/img/about/2.png';
import imgItem3 from 'assets/img/about/3.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay]);

export const AboutTCC = ({ className, id }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(className, 'mt-10')} id={id}>
      <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
        <div className="wrapper-lg">
          <h2 className="text-black uppercase m-auto mb-20">
            <span className="bg-orange text-white mr-2 ml-2 xs:mr-5">
              {t('About')}
            </span>
            {t('the crane club')}
          </h2>
          <div>
            <div className="flex items-center flex-col xs:flex-row justify-center m-auto">
              <img src={imgItem1} alt="" />
              <p className="p2 max-w-[520px]">
                Years of experience through many online marketplaces for
                equipment buying and selling have led us to develop The Crane
                Club. The Crane Club – a social media Marketplace like no other
                offers a unique concept to the Crane and Lifting Industry.
              </p>
            </div>
            <div className="flex items-center flex-col xs:flex-row-reverse justify-center m-auto my-20">
              <img src={imgItem2} alt="" />
              <p className="p2 max-w-[520px]">
                We want to make a difference in this industry and create a
                platform that encourages networking for not only buying,
                selling, and renting equipment…but for the people to share ideas
                and information with each other in a dedicated platform for the
                industry.
              </p>
            </div>
            <div className="flex items-center flex-col xs:flex-row justify-center m-auto">
              <img src={imgItem3} alt="" />
              <p className="p2 max-w-[520px]">
                We believe given the right platform and tools will revolutionize
                antiquated approaches to representing equipment and services in
                the crane industry. We are confident through The Crane Club
                platform that if you are in the industry, you will find people,
                groups, jobs, equipment and so much more and develop your
                network and exposure through The Crane Club.
              </p>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

AboutTCC.propTypes = {
  className: PropTypes.string
};
