import PropTypes from 'prop-types';
import cn from 'classnames';
import SwiperCore, { Autoplay } from 'swiper/core';
import imgItem from 'assets/img/item-social-club.png';
import { Icon, TitleItem, ContentItem, LinkItem } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay]);


export const InviteSocialClub = ({ className, id}) => {

  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'mt-10'
      )}
      id={id}
    >
      <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
      <div className="wrapper-xs">
        <div className="flex xs:justify-between flex-col xs:flex-row">
          <div className="w-full xs:w-[48%]">
            <img src={imgItem} alt="Social Club" />
          </div>
          <div className="w-full xs:w-[48%] flex flex-col items-center xs:items-start p-6">
            <Icon width={132}
            height={64}
            name="SocialClubNotification"
            className={cn('transform transition-all cursor-pointer')}
            />
            <TitleItem text={t('ShareYourPassion')} className="xs:text-left"/>
            <ContentItem className="xs:text-left" text ={t('InviteYouToShare')} />
            <LinkItem text={t('beMember')} href={"https://thecraneclub.com/"}/>
          </div>
        </div>
      </div>
      </AnimationOnScroll>
    </div>

  );
};

InviteSocialClub.propTypes = {
  className: PropTypes.string
};