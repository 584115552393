import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ImgBg from 'assets/img/bg-footer.png';
import AppleCS from 'assets/img/appstore.svg';
import GoogleCS from 'assets/img/googleplay.svg';
import TCC from 'assets/img/tcc-orange.png'
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { Icon } from '..';
import { SectionVideo } from '..';
import { LanguagePicker } from 'components';
import './index.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';

const VIDEOID = process.env.REACT_APP_YOUTUBE_VIDEO_ID_SHARE_YOUR_CONTENT

export const FooterUser = ({ className, id }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'w-full bg-black-primary relative pt-9 xs:pt-0 pb-6 xs:pb-0'
      )}
      id={id}
    >
      <div className="block absolute z-10 top-[-25%] lg:top-[-30%] w-full">
        <SectionVideo
          videoId={VIDEOID}
          text="See our reel"
          textClasses="absolute top-1/4"
        />
      </div>
      <div style={{ backgroundImage: `url(${ImgBg})`, backgroundSize: `contain` }} className="wrapper-lg md:pt-[350px] bg-no-repeat bg-right bg-size-cover">

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <h2 className="text-white">
            {t('JoinUsAnd')} <br />
            {t('bePartOf')} <br />
             <span className="bg-orange">THE CRANE CLUB</span></h2>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <p className="text-gray text-center my-10 xs:my-16 md:max-w-[50%] m-auto">{t('WeAreDeveloping')}</p>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <a href="https://thecraneclub.com/auth/login" target="_blank"><Button size="md" className="m-auto w-3/6 xs:w-3/12 px-5 mb-5 xs:mb-36">{t('joinTheClub')}</Button></a>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="hidden xs:block w-6/12 m-auto mb-9">
            <hr className="border-gray-500" />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="flex justify-between xs:w-4/12 w-3/5 m-auto items-center mb-10" id="store">
            <a href="https://ios.thecraneclub.com" target="_blank">
              <img src={AppleCS} alt="Apple-store" />
            </a>
            <a href="https://android.thecraneclub.com" target="_blank">
              <img src={GoogleCS} alt="Google-play" />
            </a>
          </div>
        </AnimationOnScroll>
        <div className="flex justify-between items-center flex-col xs:flex-row text-center xs:text-left">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <div>
              <span className="text-xs text-white mb-5 pl-4">{t('followUs')}</span>
              <div className="flex justify-between items-center follow-us">
                <a href="https://www.instagram.com/thecraneclub.ok/" className="p-4 transition-all social" target="_blank"><Icon name="Instagram" className="transition-all" /></a>
                <a href="https://www.facebook.com/thecraneclub.ok" className="p-4 transition-all social" target="_blank"><Icon name="FacebookOrange" className="transition-all" /></a>
                <a href="https://www.linkedin.com/company/the-crane-club" className="p-4 transition-all social" target="_blank"><Icon name="Linkedin" className="transition-all" /></a>
                <a href="https://twitter.com/TheCraneClub" className="p-4 transition-all social" target="_blank"><Icon name="Twitter" className="transition-all" /></a>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <div>
              <img src={TCC} alt="The Crane Club" />
            </div>
          </AnimationOnScroll>

          <div className="flex justify-between items-center">

            <a href="https://thecraneclub.com/privacy-policy" className="p-2 legal" target="_blank">
              <span className="font-normal text-xs text-white relative">{t('privacyPolicies')}</span>
            </a>
            <a href="https://thecraneclub.com/terms-and-conditions" className="p-2 legal" target="_blank">
              <span className="font-normal text-xs text-white relative">{t('terms&conditions')}</span>
            </a>
            <div>
              <LanguagePicker></LanguagePicker>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

FooterUser.propTypes = {
  className: PropTypes.string
};
