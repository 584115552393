import axios from 'utils/axios';

export const getFeaturedBrands = async () => {
  try {
    const { data } = await axios.get('api/brands/featured');
    return data?.brands || [];
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
