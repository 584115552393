import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import TCC from 'assets/img/orange.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { isIOS } from 'react-device-detect'


export const BannerDownload = ({ className = '' }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'block xs:hidden w-full py-5 bg-white'
      )}
    >
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
      <div className="wrapper-lg m-auto flex items-center justify-between" activeClass="active">
          <img src={TCC} alt="" className="max-w-[75px]"/>
          <p className="text-sm px-3">{t('DownloadOurApp')}<br />
          {t('bePartOf')} {t('theCommunity')}
          </p> 
          <a href= { isIOS ? "https://ios.thecraneclub.com" : "https://android.thecraneclub.com" } target="_blank">
            <Button className="px-4">{t('download')}</Button>
          </a>
      </div>
      </AnimationOnScroll>
    </div>
  );
};

BannerDownload.propTypes = {
  className: PropTypes.string
};