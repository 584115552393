import PropTypes from 'prop-types';
import cn from 'classnames';
import SwiperCore, { Autoplay } from 'swiper/core';
import metrics from 'assets/img/metrics.png';
import imgVip from 'assets/img/vip-member.svg';
import ImgBg from 'assets/img/bg-vip.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay]);


export const InviteToVip = ({ className, id }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'mt-10'
      )}
      id={id}
    >
      <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true}>
        <div className="bg-no-repeat bg-left bg-size-cover" style={{ backgroundImage: `url(${ImgBg})`, backgroundSize: `contain` }}>
          <div className="wrapper-lg">
            <div className="flex flex-col items-center justify-center xs:flex-wrap">
              <h2>{t('doYouWantBe')}</h2>
              <div className="flex items-center justify-around">
                <img src={imgVip} alt="" className="w-[15rem] xs:w-full px-2" />
                <h2>?</h2>
              </div>
            </div>
            <p className="text-gray text-center my-10 xs:max-w-[50%] m-auto">{t('LoginAccess')}</p>
            <div className="">
              <img src={metrics} alt="" className="m-auto" />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>

  );
};

InviteToVip.propTypes = {
  className: PropTypes.string
};