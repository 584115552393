import PropTypes from 'prop-types';
import cn from 'classnames';
import 'swiper/swiper.min.css';
import Club from 'assets/img/club.png';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Icon } from '..';
import imgBankJob from 'assets/img/bank-job.png';
import { useMediaQuery } from 'react-responsive';
import 'animate.css/animate.min.css';
import './index.css';

export const BankJob = ({ className = '', id }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  return (
    <div className={cn(className, 'bg-black-primary relative py-18')} id={id}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div
          className="bg-black-primary py-22 pb-[5%] bg-no-repeat"
          style={{
            backgroundImage: `url(${Club})`,
            backgroundPosition: `50% 90%`,
            backgroundSize: isMobile ? `contain` : `auto`,
          }}
        >
          <div className="wrapper-lg h-full">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={200}>
              <h2 className="text-white uppercase max-w-[80%] m-auto">
                {t('uploadYourCvAndLook')}
                <span className="bg-orange text-white mr-2 ml-2 xs:mr-5">{t('Vacances')}</span>
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={220}>
              <div className="wrapper-lg my-[100px]">
                <img className="m-auto" src={imgBankJob} alt="Find-job" />
              </div>
            </AnimationOnScroll>
            <div className="text-center wrapper-lg">
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                <div className="w-3/6 md:w-1/6 m-auto">
                  <a href="https://thecraneclub.com/auth/login" target="_blank">
                    <Button className="mb-6">{t('CreateAccount')}</Button>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

BankJob.propTypes = {
  className: PropTypes.string

};