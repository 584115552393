import PropTypes from 'prop-types';
import cn from 'classnames';
import 'swiper/swiper.min.css';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Icon } from '..';
import imgMetrics from 'assets/img/metrics.png';
import { useMediaQuery } from 'react-responsive';
import 'animate.css/animate.min.css';

export const Metrics = ({ className = '', id }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  return (
    <div className={cn(className, 'relative py-18')} id={id}>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div className="py-22">
          <div className="wrapper-lg h-full">
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce={true}
              delay={200}
            >
              <h2 className="uppercase m-auto">
                Enjoy the access to all <br /> our
                <span className="bg-orange text-white mr-2 ml-2 xs:mr-5">
                  exclusive metrics
                </span>
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce={true}
              delay={220}
            >
              <div className="wrapper-lg my-[80px]">
                <img className="m-auto" src={imgMetrics} alt="Find-job" />
              </div>
            </AnimationOnScroll>
            <div className="text-center wrapper-lg">
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                animateOnce={true}
              >
                <div className="w-3/6 md:w-1/6 m-auto">
                  <a href="https://thecraneclub.com/auth/login" target="_blank">
                    <Button className="mb-6">{t('CreateAccount')}</Button>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

Metrics.propTypes = {
  className: PropTypes.string
};
