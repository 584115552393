import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import i18n from './i18n';
import Landing from 'pages/Landing';
import LandingUser from 'pages/LandingUser';
import './index.css';
import 'animate.css/animate.min.css';

export const baseUrl = '/' + i18n.language;

const App = () => {
  return (
    <Router basename={baseUrl}>
      <Switch>
        <Route path="/:locale?/users">
          <LandingUser />
        </Route>
        <Route path="/:locale?">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
