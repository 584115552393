import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';
import TCC from 'assets/img/tcc-orange.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

export const OurApp = ({ className }) => {
  const { t } = useTranslation();

  const scrollOptions = {
    activeClass: "active",
    smooth: "easeOutCubic",
    spy: true,
  };
  return (
    <div
      className={cn(
        className,
        ''
      )}
    >
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <div>
          <img src={TCC} alt="The Crane Club" />
          <p className="text-4xl pl-[80px]">{t('Our')} 
          <Link
            className="px-2 anchor cursor-pointer"
            to="store"
            duration= {800}
            {...scrollOptions}
          >
            <span className="text-orange">App </span>{t('already')}
          </Link><br />
          {t('availableNow')}</p>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

OurApp.propTypes = {
  className: PropTypes.string,
};