import axios from 'utils/axios';

export const getFeatured = async (page, size) => {
  try {
    const { data } = await axios.get(
      `api/auth/users/featured?page=${page}&size=${size}`
    );
    return data;
  } catch (error) {
    if (process.env.REACT_APP_DEV_MODE !== 'true') {
      console.log('ERROR: ', error.message);
    }
    throw error;
  }
};
