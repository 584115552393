import PropTypes from 'prop-types';
import cn from 'classnames';
import 'swiper/swiper.min.css';
import Slide from 'assets/img/slide-bg.png';
import ImgUpSlide from 'assets/img/crane-community.png';
import Video from 'assets/chat-video.mp4'
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Icon } from '..';
import { useMediaQuery } from 'react-responsive';
import 'animate.css/animate.min.css';
import './index.css';

export const CraneCommunity = ({ className = '', id }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });
  return (
    <div className={cn(className, 'relative')} id={id}>
      <div className="wrapper-lg h-full">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={200}>
          <h2 className="text-black uppercase max-w-full xs:max-w-[70%] m-auto">
            {t('ChatWithFriends')} <br />
            {t('OfThe')} <br className="block xs:hidden" /><span className="bg-orange text-white mr-2 ml-2 xs:mr-5">{t('CraneCommunity')}</span>
          </h2>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={220}>
        <div
          className="pt-[15%] xs:pt-[150px] pb-[5%] bg-no-repeat"
          style={{
            backgroundImage: `url(${Slide})`,
            backgroundPosition: `50% 50%`,
            backgroundSize: isMobile ? `contain` : `auto`,
          }}
        >
          <div className="relative">
            <div className="wrapper-lg m-auto">
              {/* <img className="m-auto" src={ImgUpSlide} alt="" /> */}
              <video src={Video} autoPlay muted loop></video>
            </div>
            <div className="absolute flex items-center justify-between w-4/12 xs:w-3/12 top-[-15%] right-1/2 translate-x-2/4">
              <div className="relative bg-orange-light px-5 py-4 xs:px-12 xs:py-12 rounded-full drop-shadow-2xl">
                <Icon name="Chat"
                  canvasWidth={isMobile ? 25 : 72}
                  canvasHeigth={isMobile ? 25 : 72}
                  center
                  width={isMobile ? 20 : 56}
                  height={isMobile ? 25 : 72}
                />
              </div>
              <div className="relative bg-orange-light px-5 py-4 xs:px-12 xs:py-12 rounded-full drop-shadow-2xl">
                <Icon name="Users"
                  canvasWidth={isMobile ? 25 : 72}
                  canvasHeigth={isMobile ? 25 : 72}
                  center
                  width={isMobile ? 20 : 56}
                  height={isMobile ? 25 : 72}
                />
              </div>
            </div>
          </div>

        </div>
      </AnimationOnScroll>
      <div className="text-center wrapper-lg">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="w-3/6 md:w-1/6 m-auto">
            <a href="https://thecraneclub.com/auth/login" target="_blank">
              <Button className="mb-6">{t('joinTheClub')}</Button>
            </a>
          </div>
        </AnimationOnScroll>
      </div>

    </div>
  );
};

CraneCommunity.propTypes = {
  className: PropTypes.string

};