import cn from 'classnames';
import PropTypes from 'prop-types';
import { Logo } from 'components/Logo';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { Link } from 'react-scroll'
import { useEffect } from 'react';
import './index.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-responsive'; 



export const HeaderUser = ({ className = '' }) => {
  const { t } = useTranslation();
  const scrollOptions = {
    activeClass: "active",
    smooth: "easeOutCubic",
    spy: true,
    offset: isMobile ? 0 : -140,
  }

  return (

    <header className={cn(className, ' bg-orange-background', 'xs:fixed', 'w-full', 'z-50')}>

      <div className="wrapper-lg flex items-center justify-center xs:justify-between py-5">
      <div className="">
        {/* {isDesktop && ( */}
        <Logo
          size="big"
          className="cursor-pointer md:mr-2 lg:mr-16 transition hover:opacity-70"
        // onClick={() => history.push('/')}
        />
        {/* )} */}
      </div>

      <div className="items-center justify-between hidden lg:flex">
        
        <div className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer" href="https://thecraneclub.com/">
          <Link
            className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer"
            to="hero-user"
            duration= {800}
            {...scrollOptions}
          >
            <span className="font-bold text-black text-sm whitespace-nowrap">Home</span>
          </Link>
        </div>
        <Link
          className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer"
          to="cv"
          duration= {1200} 
          {...scrollOptions}
        >
          <span className="font-bold text-black text-sm whitespace-nowrap">Curriculum</span>
        </Link>
        <div className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer">
          <Link
            className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer"
            to="community"
            duration= {1400}
            {...scrollOptions}
          >
            <span className="font-bold text-black text-sm whitespace-nowrap">Community</span>
          </Link>
        </div>

      </div>
      <div className="flex items-center justify-between">
        <span className="text-xs leading-3 mr-5 text-gray-400 whitespace-nowrap hidden md:block">{t('100%free')}</span>
        <div className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer hidden xs:block">
          <Link
            className="px-1 xs:px-2 md:px-5 py-1 anchor cursor-pointer"
            to="store"
            duration= {1400}
            {...scrollOptions}
          >
            <Button size="md" className="px-5">{t('DownloadOurApp')}</Button>
          </Link>
        </div>
        <a href="https://thecraneclub.com/auth/register" target="_blank" className="hidden xs:block">
          <Button size="md" className="px-5">{t('createYourAccount')}</Button>
        </a>
      </div>

      </div>
    </header>

  );
};

HeaderUser.propTypes = {
  className: PropTypes.string
};
