import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ImgBg from 'assets/img/bg-footer.png';
import TopFooter from 'assets/img/top-footer.png';
import AppleCS from 'assets/img/appstore.svg';
import GoogleCS from 'assets/img/googleplay.svg';
import TCC from 'assets/img/tcc-orange.png';
import { Button } from '@estudio-nk/the-crane-club-client-lib';
import { Icon } from '..';
import { LanguagePicker } from 'components';
import './index.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';

export const Footer = ({ className, id }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'w-full bg-black-primary relative pt-9 xs:pt-0 pb-6 xs:pb-0'
      )}
      id={id}
    >
      {/* <div className="hidden md:block absolute z-10 top-[-2%] lg:top-[-30%] w-full">
        <img src={TopFooter} alt="100% FREE" className="max-w-screen-sm lg:min-w-[1000px] m-auto" />
      </div> */}
      <div
        style={{ backgroundImage: `url(${ImgBg})`, backgroundSize: `contain` }}
        className="wrapper-lg md:pt-[350px] bg-no-repeat bg-right bg-size-cover"
      >
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <h2 className="text-white max-w-[80%] m-auto">
            {t('stayConnect')}{' '}
            <span className="bg-orange">{t('craneWorld')}</span>
          </h2>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <p className="text-gray text-center my-10 xs:my-16 xs:max-w-[50%] m-auto">
            {t('WeAreDeveloping')}
          </p>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <a href="https://thecraneclub.com/auth/login" target="_blank">
            <Button
              size="md"
              className="m-auto w-3/6 xs:w-3/12 px-5 mb-5 xs:mb-36"
            >
              {t('joinTheClub')}
            </Button>
          </a>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <a href="tel:+1239-366-5868" className="flex items-center justify-center">
            <svg className='hidden xs:block'
              width="62"
              height="77"
              viewBox="0 0 62 77"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.1576 20.363L29.1576 20.363L28.1784 17.9955L27.1382 15.5204C25.3472 11.3556 23.2367 7.14418 20.1859 3.78414C19.8259 3.39091 19.3701 3.09773 18.8628 2.93336C18.3546 2.7687 17.8124 2.73896 17.2894 2.84702L17.2839 2.84815L17.2839 2.84814C10.7492 4.17918 4.35741 9.70207 3.25515 16.1925L3.10693 17.17L3.01861 17.9141L2.94146 18.7518L2.8835 19.6801L2.84092 20.7115C2.83153 21.0648 2.82698 21.4182 2.82726 21.7716L2.84847 22.9503L2.90799 24.1979L3.00649 25.5163L3.16006 26.9189C3.95377 33.3112 6.37805 41.7995 12.6496 51.3358C18.924 60.8763 25.7583 66.4661 31.3141 69.7285L32.5414 70.4255L33.713 71.0385L34.8431 71.5915C35.0152 71.6713 35.1879 71.7499 35.3612 71.8273C35.3627 71.828 35.3643 71.8287 35.3659 71.8294L36.4258 72.2961C36.5798 72.3604 36.7342 72.4237 36.8889 72.486L37.8453 72.8528L38.7253 73.1623L39.5317 73.4291L40.2495 73.6428L41.2049 73.8974C47.6297 75.4628 55.1695 71.8311 58.9986 66.3382L59.0012 66.3345C59.3174 65.8831 59.5066 65.3551 59.549 64.8057C59.5914 64.257 59.4859 63.707 59.2435 63.2129C56.8564 58.3906 53.2286 54.3453 49.6419 50.5743L29.1576 20.363ZM29.1576 20.363L29.161 20.3712C29.4641 21.0951 29.4903 21.9052 29.2345 22.647C28.9859 23.368 28.4879 23.9757 27.8313 24.3613L20.3012 27.8594C19.4994 28.2231 18.8554 28.8639 18.4878 29.6645C18.118 30.47 18.0532 31.3823 18.3054 32.2319L18.3085 32.2424L18.3118 32.2528C19.2794 35.3866 21.1718 40.1742 23.7268 44.0593L23.7274 44.0602C26.2827 47.941 30.0768 51.7854 32.7081 54.1111L32.7146 54.1169L32.7211 54.1225C33.4294 54.7377 34.3465 55.0583 35.2838 55.0184C36.2151 54.9787 37.0963 54.586 37.7485 53.9204L43.3394 48.3273C43.9528 47.8065 44.7411 47.5359 45.5467 47.5711C46.3711 47.6072 47.1494 47.9607 47.7189 48.5575C47.719 48.5576 47.7191 48.5577 47.7192 48.5578L49.6402 50.5725L29.1576 20.363Z"
                stroke="#FF542C"
                stroke-width="4"
              />
            </svg>
            <p className="p-link-footer xs:ml-10">+1 239-366-5868</p>
          </a>
          <a href="mailto:info@thecraneclub.com" className="flex items-center justify-center mt-10 xs:mt-20 mb-10">
            <svg className='hidden xs:block'
              width="65"
              height="46"
              viewBox="0 0 65 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.8582 25.7876L28.535 27.6782L29.8582 25.7876ZM35.1418 25.7876L36.465 27.6782L35.1418 25.7876ZM6.96429 4.6H58.0357V0H6.96429V4.6ZM60.3571 6.9V39.1H65V6.9H60.3571ZM58.0357 41.4H6.96429V46H58.0357V41.4ZM4.64286 39.1V6.9H0V39.1H4.64286ZM6.96429 41.4C6.3486 41.4 5.75814 41.1577 5.32279 40.7263C4.88744 40.295 4.64286 39.71 4.64286 39.1H0C0 40.93 0.733735 42.685 2.03979 43.979C3.34585 45.273 5.11724 46 6.96429 46V41.4ZM60.3571 39.1C60.3571 39.71 60.1126 40.295 59.6772 40.7263C59.2419 41.1577 58.6514 41.4 58.0357 41.4V46C59.8828 46 61.6542 45.273 62.9602 43.979C64.2663 42.685 65 40.93 65 39.1H60.3571ZM58.0357 4.6C58.6514 4.6 59.2419 4.84232 59.6772 5.27365C60.1126 5.70499 60.3571 6.29 60.3571 6.9H65C65 5.07001 64.2663 3.31496 62.9602 2.02096C61.6542 0.726962 59.8828 0 58.0357 0V4.6ZM6.96429 0C5.11724 0 3.34585 0.726962 2.03979 2.02096C0.733735 3.31496 0 5.07001 0 6.9H4.64286C4.64286 6.29 4.88744 5.70499 5.32279 5.27365C5.75814 4.84232 6.3486 4.6 6.96429 4.6V0ZM0.998214 8.7906L28.535 27.6782L31.1791 23.897L3.64232 5.0094L1.00054 8.7906H0.998214ZM36.465 27.6782L63.9995 8.7906L61.3577 5.0094L33.8209 23.897L36.465 27.6782ZM28.535 27.6782C29.6999 28.4775 31.0831 28.9057 32.5 28.9057C33.9169 28.9057 35.3001 28.4775 36.465 27.6782L33.8209 23.897C33.4328 24.1631 32.972 24.3056 32.5 24.3056C32.028 24.3056 31.5672 24.1631 31.1791 23.897L28.535 27.6782Z"
                fill="#FF542C"
              />
            </svg>
            <p className="p-link-footer xs:ml-10">INFO@THECRANECLUB.COM</p>
          </a>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="hidden xs:block w-6/12 m-auto mb-9">
            <hr className="border-gray-500" />
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <div
            className="flex justify-between xs:w-4/12 w-3/5 m-auto items-center mb-10"
            id="store"
          >
            <a href="https://ios.thecraneclub.com" target="_blank">
              <img src={AppleCS} alt="Apple-store" />
            </a>
            <a href="https://android.thecraneclub.com" target="_blank">
              <img src={GoogleCS} alt="Google-play" />
            </a>
          </div>
        </AnimationOnScroll>
        <div className="flex justify-between items-center flex-col xs:flex-row text-center xs:text-left">
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <div>
              <span className="text-xs text-white mb-5 pl-4">
                {t('followUs')}
              </span>
              <div className="flex justify-between items-center follow-us">
                <a
                  href="https://www.instagram.com/thecraneclub.ok/"
                  className="p-4 transition-all social"
                  target="_blank"
                >
                  <Icon name="Instagram" className="transition-all" />
                </a>
                <a
                  href="https://www.facebook.com/thecraneclub.ok"
                  className="p-4 transition-all social"
                  target="_blank"
                >
                  <Icon name="FacebookOrange" className="transition-all" />
                </a>
                <a
                  href="https://www.linkedin.com/company/the-crane-club"
                  className="p-4 transition-all social"
                  target="_blank"
                >
                  <Icon name="Linkedin" className="transition-all" />
                </a>
                <a
                  href="https://twitter.com/TheCraneClub"
                  className="p-4 transition-all social"
                  target="_blank"
                >
                  <Icon name="Twitter" className="transition-all" />
                </a>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <div>
              <img src={TCC} alt="The Crane Club" />
            </div>
          </AnimationOnScroll>

          <div className="flex justify-between items-center">
            <a
              href="https://thecraneclub.com/privacy-policy"
              className="p-2 legal"
              target="_blank"
            >
              <span className="font-normal text-xs text-white relative">
                {t('privacyPolicies')}
              </span>
            </a>
            <a
              href="https://thecraneclub.com/terms-and-conditions"
              className="p-2 legal"
              target="_blank"
            >
              <span className="font-normal text-xs text-white relative">
                {t('terms&conditions')}
              </span>
            </a>
            <div>
              <LanguagePicker></LanguagePicker>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};
