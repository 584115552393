import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';
import { useTranslation } from "react-i18next";

export const LabelVideo = ({ className, text }) => {
  const { t } = useTranslation();
  return (
      <div
          className={cn(
              className,
              'py-1 xs:py-3 bg-orange'
          )}
      >
      <span className="text-white text-xs font-semibold">{t(text)}</span>
      </div>
  );
};

LabelVideo.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired
};