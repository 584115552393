import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('tcc-token');
    const lng = localStorage.getItem('i18nextLng') || 'en';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.params = { lng };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      localStorage.removeItem('tcc-token');
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
