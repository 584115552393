import React from 'react';
import Layout from 'Layout';
import { HeroUser, HeaderUser, FindYourJob, CraneCommunity, FooterUser, BannerDownload, InviteToVip} from 'components';



const LandingUser = () => {
  return (
    <Layout>
      <>
        <HeaderUser/>
        <HeroUser id="hero-user"/>
        <BannerDownload />
        <FindYourJob id="cv" />
        <CraneCommunity id="community" className="pt-[5rem] xs:pt-[10rem]" />
        <InviteToVip id="" className="pb-[20rem] xs:pb-[30rem] pt-5"/>
        <FooterUser id="footer" />
      </>
    </Layout>
  );
};

export default LandingUser;
