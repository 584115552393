import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon } from 'components';
import { useMediaQuery } from 'react-responsive';


export const ScrollButton = ({ className = '' }) => {
    const isMobile = useMediaQuery({
        query: '(max-width: 480px)'
      });

    return (
        <div
            className={cn(
                className,
                ''
            )}
        >
            <Icon 
            width={isMobile ? 30 : 80}
            height={isMobile ? 30 : 80}
            name="ArrowBottom"
            className={cn('transform transition-all m-auto cursor-pointer')}
            />
        </div>
    );
};

ScrollButton.propTypes = {
    className: PropTypes.string
};