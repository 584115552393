import PropTypes from 'prop-types';
import cn from 'classnames';
import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';

export const TitleDivision = ({ className, id }) => {
  
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'my-16 xs:mt-36 xs:mb-32 mx-auto w-11/12'
      )}
      id={id}
    >
    <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
    <h2 className="xs:max-w-[80%] m-auto">{t('ShareYourPhotos')} <br />
    <span className="bg-orange text-white">{t('SocialNetwork')}</span> {t('inTheCraneWorld')}</h2>
    <p className="text-gray text-center my-10 xs:max-w-[50%] m-auto">{t('createYourAccountShareYourPhotos')}</p>
    </AnimationOnScroll>
    </div>
  );
};

TitleDivision.propTypes = {
  className: PropTypes.string
};