import PropTypes from 'prop-types';
import cn from 'classnames';
import SwiperCore, { Autoplay } from 'swiper/core';
import imgItem from 'assets/img/user-profile.png';
import { TitleItem, LinkItem } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';
import './index.css';

SwiperCore.use([Autoplay]);


export const UserProfile = ({ className, id }) => {

  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'mt-10'
      )}
      id={id}
    >
      <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
        <div className="wrapper-xs">
          <div className="flex xs:justify-between flex-col-reverse xs:flex-row">
            <div className="w-full xs:w-[48%] flex flex-col justify-center items-center xs:items-start p-6">
              <TitleItem text={t('UserProfile')} className="xs:text-left" />
              <ul className="min-h-[5rem] pl-4 flex flex-col items-start justify-between mb-10">
                <li className="list-disc text-left">{t('SharePhotosOfUser')}</li>
                <li className="list-disc text-left">{t('Interact')}</li>
                <li className="list-disc text-left">{t('uploadYourCvLook')}</li>
              </ul>
              <LinkItem className="text-center xs:text-left" text={t('joinTheClub')} href={"https://thecraneclub.com/auth/register"} />
            </div>
            <div className="w-full xs:w-[48%]">
              <img src={imgItem} alt="Social Club" />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>

  );
};

UserProfile.propTypes = {
  className: PropTypes.string
};