import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFeatured } from 'api/publications';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import { PublicationCard, Button } from '@estudio-nk/the-crane-club-client-lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';
import 'animate.css/animate.min.css';
import './index.css';
SwiperCore.use([Autoplay]);

export const SlideCranes = ({ className, id }) => {
  const [cranes, setCranes] = useState([]);
  const [cranesCount, setCranesCount] = useState(0);

  useEffect(() => {
    fetchPublication();
  }, []);

  const fetchPublication = async () => {
    const _cranes = await getFeatured(0, 20);
    setCranes(_cranes.rows);
    setCranesCount(_cranes.count);
  };

  const slides = cranes.map((c, i) => (
    <SwiperSlide key={`${c.id}${i}`}>
      <div className="max-w-sm m-auto">
        <PublicationCard
          publication={c}
          containerClasses="min-h-[590px] xs:min-h-[550px]"
        />
      </div>
    </SwiperSlide>
  ));

  const { t } = useTranslation();
  return (
    <div className={cn(className, 'mt-10 relative fade-section pb-10')} id={id}>
      <div className="absolute w-full h-full md:-top-[10%] lg:-top-1/4">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <span className="hidden md:block md:text-[350px] lg:text-[500px] text-gray font-bold opacity-30">
            {cranesCount}
          </span>
        </AnimationOnScroll>
      </div>
      <div className="wrapper-lg h-full">
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <h2>
          <span className="bg-orange text-white mr-2 xs:mr-5">{t('find')}</span>
          {t('hundredsOfCranes')}
        </h2>
        </AnimationOnScroll>
      </div>
      <div className="w-full">
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={500}>
        <div className="xs:mt-7 md:mt-12 bg-transparent rounded-lg w-full m-auto">
          <Swiper
            spaceBetween={24}
            slidesPerView={1}
            freeMode={true}
            breakpoints={{
              1200: {
                slidesPerView: 5
              },
              800: {
                slidesPerView: 3
              },
              600: {
                slidesPerView: 2
              }
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }}
            className="thumbs-swiper swiper-container-cards"
          // autoHeight={true}
          >
            {slides}
          </Swiper>
        </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={500}>
        <div className="text-center wrapper-lg">
          <div className="w-3/6 md:w-1/6 m-auto">
            <a href="https://thecraneclub.com/auth/login" target="_blank">
              <Button className="mb-6">{t('createYourAccount')}</Button>
            </a>
          </div>
          <span className="text-gray whitespace-nowrap text-base">
            {t('andSeeCranes')}
          </span>
        </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

SlideCranes.propTypes = {
  className: PropTypes.string
};
