import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import cn from 'classnames';

export const LanguagePicker = ({ className }) => {
  const { i18n } = useTranslation();

  const handleOnLanguageChange = () => {
    const lng = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(lng);
  };

  return (
    <div
      onClick={handleOnLanguageChange}
      className={cn('flex text-2xs text-gray cursor-pointer ml-5', className)}
    >
      {i18n.language === 'en' ? <Icon name="US" /> : <Icon name="Spain" />}
    </div>
  );
};
