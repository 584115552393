import PropTypes from 'prop-types';
import cn from 'classnames';
import SwiperCore, { Autoplay } from 'swiper/core';
import imgItem from 'assets/img/item-publish.png';
import { TitleItem, ContentItem, LinkItem } from 'components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay]);


export const InviteToPublish = ({ className, id }) => {

  const { t } = useTranslation();
  return (
    <div
      className={cn(
        className,
        'mt-10'
      )}
      id={id}
    >
      <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce={true}>
      <div className="wrapper-xs">
        <div className="flex xs:justify-between flex-col-reverse xs:flex-row">
          <div className="w-full xs:w-[48%] flex flex-col items-center xs:items-end p-6">
            <TitleItem text={t('startSharing')} className="xs:text-right"/>
            <ContentItem className="xs:text-right" text={t('InviteYouToShareCranes')} />
            <LinkItem text={t('ShareYourCranes')} href={"https://thecraneclub.com/publish"} className="text-right"/>
          </div>
          <div className="w-full xs:w-[48%]">
            <img src={imgItem} alt="Publish cranes" />
          </div>
        </div>
      </div>
      </AnimationOnScroll>
    </div>

  );
};

InviteToPublish.propTypes = {
  className: PropTypes.string
};